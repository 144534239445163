body {
   background-image: url(../assets/bg_main.png);
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

ul {
   margin: 0;
   padding: 0;
}

.container {
   max-width: 1400px;
   /* max-width: 100vw; */
   background-color: white;
   padding: 20px 20px 20px 20px;
   /* margin-bottom: 10px!important; */
   /* height: 100vh; */
}

.form-control {
   margin-bottom: 10px;
}

.form-check-label {
   /* No marking */
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.no-user-select {
   /* No marking */
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.navbar-brand {
   display: flex;
   align-items: center;
}

.navbar-brand > img {
   height: 60px;
}

/* IOS */
select {
   -webkit-appearance: none;
}

input::-webkit-date-and-time-value {
   text-align: left;
   margin-left: 2.5px;
   /* -webkit-appearance: none; */
}

.modal-80w {
   max-width: 70%;
}

.labelClass {
   margin-top: -40px;
   background-color: rgb(255, 255, 255, 0.8);
   padding: 3px;
   border: solid 1px black;
   text-align: center;
}

.pointer_on_hover:hover {
   cursor: pointer;
}

.labelClass:hover {
   background-color: rgb(255, 255, 255, 1);
}

.green-cell {
   background-color: green !important;
}

.row-highlight-provisional > div > div {
   background-color: orange !important;
}

.row-highlight-illegal > div > div {
   background-color: gray !important;
}

/* Mobile phones */
@media (max-width: 768px) {
   .btn,
   .btn-group {
      /* width: 100%; */
   }

   .container {
      box-sizing: border-box;
      padding: 10px;
   }

   .navbar-brand > img {
      height: 35px;
   }
}

div[role='dialog'][aria-modal='true']:nth-last-child(1) {
   z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
   z-index: 1100;
}

div[role='dialog'][aria-modal='true']:nth-last-child(3) {
   z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
   z-index: 1050;
}

div[role='dialog'][aria-modal='true']:nth-last-child(5) {
   z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
   z-index: 1000;
}

input[type='checkbox'] {
   transform: scale(1.3, 1.3);
}

.bookButton {
   /* background-color: #370344 !important; */
   background-color: #75d701 !important;
   border-color: #75d701;
}

/*
.spin {
  width: 100px;
  height: 100px;
  background: red;
  animation: spin 0.1s linear;
  animation-delay: 0s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
} */
