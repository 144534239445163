/* Mobile phones */
@media (max-width: 768px) {
   .mooring-item .button-container {
      padding-left: 10px !important;
      margin-left: 25px !important;
   }

   .mooring-item .mooring-logo {
      margin-top: 25px !important;
      width: 50px !important;
      height: 50px !important;
   }

   .add-new-button .btn {
      max-width: none !important;
      width: 100% !important;
   }
}
