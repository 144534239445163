/* .booking-table p {
  margin: 0;
  padding: 0;
} */

.booking-table .t-container {
   /* background-color: white; */
   overflow-x: scroll;
   /* border: 1px solid black; */
}

.booking-table .today {
   background-color: rgb(180, 180, 180) !important;
}

.booking-table .weekend {
   background-color: rgb(225, 225, 225);
}

.booking-table .date-table-header {
   width: 100px;
}

.booking-table table {
   table-layout: fixed;

   cursor: default;

   /* No marking */
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   /* Don't collapse */
   border-collapse: separate;
   border-spacing: 0;
}

.booking-table td {
   text-align: center;
   align-content: center;
   border-right: 1px solid black;
   border-bottom: 1px solid black;
}

.booking-table th {
   text-align: center;
   border-right: 1px solid black;
   border-bottom: 1px solid black;
   border-top: 1px solid black;
   position: sticky;
   top: 0px;
   background-color: white;
   z-index: 3;
}

.booking-table td:first-child,
th:first-child {
   border-left: 1px black solid;
}

.booking-table .available {
   cursor: pointer;
}

.booking-table th:hover {
   background-color: rgb(200, 200, 200);
}

.booking-table tr:hover {
   background-color: rgb(200, 200, 200);
}

.booking-table .booked {
   cursor: pointer;
   /* background-clip: border-box; */
   border-left: 0px black solid;
   border-right: 0px black solid;
}

.booking-table .booked.first {
   border-left: 1px black solid;
}

.booking-table .booked.last {
   border-right: 1px black solid;
}

.hovered {
   background-color: #00b801 !important;
   cursor: pointer;
}

.selected-range {
   background-color: #00b801 !important;
}

.hovered-illegal {
   background-color: rgba(0, 0, 0, 0.5) !important;
}

.booking-table .wrapper {
   white-space: nowrap;
   overflow: hidden;
}

.booking-table .sticky-col {
   position: sticky;
}

.booking-table .mooring-col {
   overflow: hidden;
   z-index: 4;
   background-color: white;
}

th.mooring-col {
   z-index: 5 !important;
}

th.first-col {
   z-index: 6 !important;
}

td.first-col {
   z-index: 5 !important;
}

.booking-table .first-col {
   width: 100px;
   left: 0px;
   text-align: left;
}

.booking-table .first-col:hover {
   background-color: rgb(200, 200, 200);
}

.booking-table .second-col {
   width: 100px;
   left: 100px;
}

.booking-table .third-col {
   width: 80px;
   left: 180px;
}

.booking-table .fourth-col {
   width: 80px;
   left: 260px;
}

.booking-table .date-col {
   width: 80px;
   min-width: 80px;
   max-width: 80px;
}

#date_table_div {
   width: 100%;
   max-height: calc(100vh - 120px);
   overflow: auto;
}

@media (max-width: 768px) {
   .booking-table .second-col {
      left: 0px;
   }

   .booking-table .third-col {
      left: 0px;
   }

   .booking-table .fourth-col {
      left: 0px;
   }
}

/*
.test {
  margin: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid black;
  z-index: 1000;
  position: relative;
  float: right;

} */
